<template>
    <div class="c-input">
        <label class="label" :for="idFromLabel">{{ label }}</label>
        <input
            :id="idFromLabel"
            :value="content"
            @input="$emit('input', $event.target.value)"
            class="input"
            type="number"
        />
    </div>
</template>
<style lang="scss">
</style>
<script>
export default {
    props: ["value", "label"],
    data() {
        return {
            content: this.value
        };
    },
    computed: {
        idFromLabel() {
            return this.label.replace(/\s/g, "").toLowerCase();
        }
    }
};
</script>


<style lang="scss" scoped>
$PRIMARY: #d7b56d;
$SECONDARY: #0a0b0e;
$TEXT_BLACK: #0a0b0e;
$SECONDARY_W: #f3f4f7;
.c-input {
    width: 100%;
    .label {
        color: $PRIMARY;
        padding-bottom: 5px;
        display: block;
    }
    .input {
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        height: 47px;
        line-height: 47px;
        background-color: $SECONDARY_W;
        border: 1px solid #666666;
        color: $TEXT_BLACK;
        padding-left: 1em;
        &:focus {
            border-block-start-style: 1px solid $PRIMARY;
        }
    }
}
</style>
