<template>
    <div class="c-select">
        <label class="label" :for="idFromLabel">{{ label }}</label>
        <div class="custom-select" :tabindex="tabindex" @blur="open = false">
            <div class="selected" :class="{ open: open }" @click="open = !open">
                {{ selected }}
            </div>
            <div class="items" :class="{ selectHide: !open }">
                <div
                    v-for="(option, i) of options"
                    :key="i"
                    @click="
                        selected = option;
                        open = false;
                        $emit('input', option);
                    "
                >
                    {{ option }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        default: {
            type: String,
            required: false,
            default: null
        },
        tabindex: {
            type: Number,
            required: false,
            default: 0
        },
        label: {
            type: String
        }
    },
    data() {
        return {
            selected: this.default
                ? this.default
                : this.options.length > 0
                ? this.options[0]
                : null,
            open: false
        };
    },
    computed: {
        idFromLabel() {
            return this.label.replace(/\s/g, "").toLowerCase();
        }
    },
    mounted() {
        this.$emit("input", this.selected);
    }
};
</script>

<style lang="scss" scoped>
$PRIMARY: #d7b56d;
$SECONDARY: #0a0b0e;
$TEXT_BLACK: #0a0b0e;
$SECONDARY_W: #f3f4f7;
.c-select {
    width: 100%;
    .label {
        color: $PRIMARY;
        padding-bottom: 5px;
        display: block;
    }
    .custom-select {
        position: relative;
        width: 100%;
        text-align: left;
        outline: none;
        height: 47px;
        line-height: 47px;
    }

    .custom-select .selected {
        background-color: $SECONDARY_W;
        border: 1px solid #666666;
        color: $TEXT_BLACK;
        padding-left: 1em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .selected.open {
        border: 1px solid $PRIMARY;
    }

    .custom-select .selected:after {
        position: absolute;
        content: "";
        top: 22px;
        right: 1em;
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-color: #fff transparent transparent transparent;
    }

    .custom-select .items {
        color: $TEXT_BLACK;
        overflow: hidden;
        border-right: 1px solid $PRIMARY;
        border-left: 1px solid $PRIMARY;
        border-bottom: 1px solid $PRIMARY;
        position: absolute;
        background-color: $SECONDARY_W;
        left: 0;
        right: 0;
        z-index: 1;
    }

    .custom-select .items div {
        color: $TEXT_BLACK;
        padding-left: 1em;
        cursor: pointer;
        user-select: none;
    }

    .custom-select .items div:hover {
        background-color: $SECONDARY;
        color: $SECONDARY_W;
    }

    .selectHide {
        display: none;
    }
}
</style>
