<template>
    <!-- <div class="cont"> -->
    <div class="instalments-calc">
        <div class="instalments-calc__grid">
            <div class="instalments-calc__gridItem">
                <CInput label="Kwota zakupu mieszkania" v-model="flat_cost" />
            </div>
            <div class="instalments-calc__gridItem">
                <CSelect
                    :options="['24', '60']"
                    :default="'24'"
                    class="select"
                    @input="_ => (months = _)"
                    label="Okres spłaty (miesiące)"
                />
            </div>
            <div class="instalments-calc__gridItem">
                <CSelect
                    :options="['20%', '30%', '40%', 'Własny']"
                    :default="'20%'"
                    class="select"
                    @input="_ => (start_cost = _)"
                    label="Wysokość wkładu własnego"
                />
            </div>
            <div class="instalments-calc__gridItem" v-if="ownStartCost">
                <CInput label="Wysokość wkładu własnego" v-model="own_start_cost" />
            </div>
            <div class="instalments-calc__gridItem">
                <div class="instalments-calc__final">
                    Kwota miesięcznej raty: <span>{{ finalInstalment }} zł</span>
                </div>
            </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import CInput from "./components/CInput.vue";
import CSelect from "./components/CSelect.vue";
export default {
    components: { CInput, CSelect },
    name: "App",
    data: () => {
        return {
            flat_cost: 332600,
            start_cost: "20%",
            months: "24",
            own_start_cost: 20000
        };
    },
    methods: {
        formatAsNumber(n) {
            n = n.toFixed(2);
            const plLocale = Intl.NumberFormat("pl-PL");
            const formatted = plLocale.format(n);
            const formatted_arr = formatted.split(",");
            if (formatted_arr.length > 1) {
                if (formatted_arr[1].length == 1) {
                    formatted_arr[1] = formatted_arr[1] + "0";
                }
            }
            return formatted_arr.join(",");
        }
    },
    computed: {
        ownStartCost() {
            return this.start_cost === "Własny";
        },
        startCost() {
            if (this.ownStartCost === true) return this.own_start_cost;
            if (this.start_cost === "20%") {
                return this.flat_cost * 0.2;
            }
            if (this.start_cost === "30%") {
                return this.flat_cost * 0.3;
            }
            if (this.start_cost === "40%") {
                return this.flat_cost * 0.4;
            }
            return 0;
        },
        borrowedMoney() {
            return this.flat_cost - this.startCost;
        },
        finalInstalment() {
            if (!this.ownStartCost) {
                let developerIncomePercentageYearly = 0.06;
                let installmentPercentage = 0.0335;
                // 24 MONTHS && 20%
                if (this.months === "24" && this.start_cost === "20%") {
                    developerIncomePercentageYearly = 0.06;
                    installmentPercentage = 0.0335;
                }
                // 24 MONTHS && 30%
                if (this.months === "24" && this.start_cost === "30%") {
                    developerIncomePercentageYearly = 0.055;
                    installmentPercentage = 0.0292;
                }
                // 24 MONTHS && 40%
                if (this.months === "24" && this.start_cost === "40%") {
                    developerIncomePercentageYearly = 0.05;
                    installmentPercentage = 0.025;
                }

                // 60 MONTHS && 20%
                if (this.months === "60" && this.start_cost === "20%") {
                    developerIncomePercentageYearly = 0.065;
                    installmentPercentage = 0.0135;
                }
                // 60 MONTHS && 30%
                if (this.months === "60" && this.start_cost === "30%") {
                    developerIncomePercentageYearly = 0.06;
                    installmentPercentage = 0.0117;
                }
                // 60 MONTHS && 40%
                if (this.months === "60" && this.start_cost === "40%") {
                    developerIncomePercentageYearly = 0.055;
                    installmentPercentage = 0.01;
                }

                const developerYearlyIncome = this.borrowedMoney * developerIncomePercentageYearly;
                const developerMonthlyIncome = developerYearlyIncome / 12;

                const cost = this.flat_cost * installmentPercentage + developerMonthlyIncome;
                return this.formatAsNumber(cost);
            } else {
                let developerIncomePercentageYearly = 0.06;
                let monthsInt = 24;
                if (this.months === "24") {
                    developerIncomePercentageYearly = 0.05;
                    monthsInt = 24;
                }
                if (this.months === "60") {
                    developerIncomePercentageYearly = 0.055;
                    monthsInt = 60;
                }
                const developerYearlyIncome = this.borrowedMoney * developerIncomePercentageYearly;
                const developerMonthlyIncome = developerYearlyIncome / 12;
                const cost = this.borrowedMoney / monthsInt + developerMonthlyIncome;
                return this.formatAsNumber(cost);
            }
        }
    }
};
</script>

<style lang="scss">
$FONT_WEIGHT_REGULAR: 400;
$FONT_WEIGHT_BOLD: 700;
$PRIMARY: #d7b56d;
$SECONDARY: #0a0b0e;
$TEXT_BLACK: #0a0b0e;
$SECONDARY_W: #f3f4f7;
html {
    font-size: 15px;
    line-height: 24px;

    font-family: "Figtree", sans-serif;
    font-display: swap;
    font-weight: $FONT_WEIGHT_REGULAR;
    color: #0a0b0e;

    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;

    min-width: 320px;
    overflow-x: hidden;
    background-color: #ededed;
}

.cont {
    max-width: 1400px;
    margin: 0 auto;
    padding: 120px 0;
}

.instalments-calc {
    position: relative;
    box-sizing: border-box;

    * {
        box-sizing: border-box;
    }
    &__grid {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    &__gridItem {
        display: block;
        width: calc(50% - 10px);
        margin-top: 10px;
        padding: 5px 10px;
        border: none;
        @media only screen and (max-width: 600px) {
            width: 100%;
        }
    }
    &__final {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 100%;
        padding-top: 10px;

        span {
            display: block;
            color: $PRIMARY;
            font-weight: $FONT_WEIGHT_BOLD;
            font-size: 2em;
            padding-top: 5px;
        }
    }
}
</style>
